import {batch, computed, effect, signal} from "@preact/signals-react";
import React, {createContext} from "react";
import {MenuItem, TextField} from "@mui/material";
import {drawMenuItems} from "./SelectDraw";

export const createAppState = createContext()

export default function AppState(props) {

    // primitive signals
    const projects = signal([])
    const draws = signal([])
    const stakeholders = signal([])
    const selectedProject = signal("")
    const selectedDraw = signal("")
    const drawDate = signal(undefined)
    const drawPackageDate = signal(undefined)
    const selectedSubmitter = signal("Borrower")
    const recommendedDisbursement = signal(undefined)
    const selectedDocuments = signal([])
    const IRDate = signal(undefined)
    const invoiceFee = signal("")
    const invoiceNumber = signal("")
    const additionalComments = signal("")
    const varianceComments = signal([])
    const alert = signal({isOpen: false, message: ""})
    const accessToken = signal("")
    const isGlobalDisabled = signal(true)
    const totalBudget = signal("")
    const requestedAmount = signal("")
    const UUID = signal(undefined)
    const resetFields = () => {
        effect(() => {
            batch(() => {
                drawDate.value = undefined
                IRDate.value = undefined
                recommendedDisbursement.value = ""
                invoiceNumber.value = ""
                invoiceFee.value = ""
                additionalComments.value = ""
                requestedAmount.value = 0
                varianceComments.value = []
                selectedDocuments.value = []
                requestedItems.value = [
                    <TextField
                        key={'placeholder'}
                        id={'placeholder'}
                        variant="standard"
                        size="small"
                        value="Please select a project and draw."
                        disabled={true}
                    />
                ]
            })
        })
    }

    const requestedItems = signal([
        <TextField
            key={'placeholder'}
            id={'placeholder'}
            variant="standard"
            size="small"
            value="Please select a project and draw."
            disabled={true}
        />
    ])

    const totalDeductions = computed(() => {
        if (requestedAmount.value > 0 && recommendedDisbursement.value > 0) {
            return requestedAmount.value - recommendedDisbursement.value
        }
        return 0
    })

    //effects
    effect(() => {
        if (selectedDraw.value && draws.value.length > 0) {
            batch(() => {
                requestedAmount.value = draws.value.find(draw => draw.id === selectedDraw.value).requestedAmount
                isGlobalDisabled.value = false
            })
        }
        return 0
    })

    effect(() => {
        // set draws selection menu upon selection of a project
        if (selectedProject.value && accessToken.value) {
            const authHeader = {
                withCredentials: true,
                credentials: 'include',
                headers: {'Authorization': `Bearer ${accessToken}`}
            }
            fetch(`https://qdproxy.services/rabbet/draws/${selectedProject.value}`, authHeader)
                .then(response => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        throw (response.error())
                    }
                })
                .then(data => {
                    // set draws
                    batch(() => {
                        // reset form fields
                        selectedDraw.value = ""
                        isGlobalDisabled.value = true
                        totalBudget.value = projects.value.find(project => project.id === selectedProject.value).budgetAmount
                        const localUUID = projects.value.find(project => project.id === selectedProject.value).customFields.customId
                        UUID.value = localUUID
                        localUUID ? selectedSubmitter.value = "Sponsor" : selectedSubmitter.value = "Borrower"
                        resetFields()
                        if (data.data.length <= 0) {
                            draws.value = []
                            throw new Error("No draws have been entered for this project.")
                        }
                        // set draw menu items
                        draws.value = data.data
                        drawMenuItems.value = draws.value.map((draw) => {
                            return (
                                <MenuItem
                                    key={draw.id}
                                    value={draw.id}
                                >
                                    {draw.name}
                                </MenuItem>
                            )
                        })
                    })
                })
                .catch((error) => {
                    alert.value = {isOpen: true, message: error.message}
                })

            fetch(`https://qdproxy.services/rabbet/stakeholders/${selectedProject.value}`, authHeader)
                .then(response => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        throw (response.error())
                    }
                })
                .then(data => {
                    // set stakeholders
                    stakeholders.value = data.data
                })
                .catch((error) => {
                    alert.value = {isOpen: true, message: error.message}
                    return []
                })
        }
    })

    return (
        <createAppState.Provider value={{
            projects,
            selectedProject,
            selectedDraw,
            drawDate,
            drawPackageDate,
            selectedSubmitter,
            recommendedDisbursement,
            selectedDocuments,
            IRDate,
            invoiceFee,
            invoiceNumber,
            additionalComments,
            varianceComments,
            alert,
            isGlobalDisabled,
            accessToken,
            totalDeductions,
            stakeholders,
            totalBudget,
            requestedAmount,
            draws,
            requestedItems,
            UUID,
            resetFields
        }}>
            {props.children}
        </createAppState.Provider>
    )
}