import * as React from 'react';
import {useContext} from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {createAppState} from "./AppState";
import {signal} from "@preact/signals-react";

export const drawMenuItems = signal([])

export default function SelectDraw() {

    const {
        draws,
        selectedDraw,
        resetFields
    } = useContext(createAppState)

    return (
        <FormControl fullWidth>
            <InputLabel id="draws-menu" required>Draw</InputLabel>
            <Select
                id="select-projects"
                value={selectedDraw.value}
                label="draw *"
                onChange={(event) => {
                    resetFields()
                    selectedDraw.value = event.target.value
                }
                }
                disabled={draws.value.length <= 0}
            >
                {drawMenuItems.value}
            </Select>
        </FormControl>
    );
}