// react core
import React from 'react';
// local files
import SupportingDocuments from "./SupportingDocuments";
import AdditionalComments from "./AdditionalComments";
import StatusAlert from "./StatusAlert";
import Discrepancies from "./Discrepancies";
import FundingInformation from "./FundingInformation";
// 3rd party modules
import {Stack} from "@mui/material";
import CreateCoverSheet from "./CreateCoverSheet";
import DrawInformation from "./DrawInformation";

export default function CoverSheetLayout() {

    return (
        <Stack
            direction="column"
            alignItems="center"
            spacing={1}
        >
            <DrawInformation/>
            <FundingInformation/>
            <SupportingDocuments/>
            <Discrepancies/>
            <AdditionalComments/>
            <CreateCoverSheet/>
            <StatusAlert/>
        </Stack>
    )
}