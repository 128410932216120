// react core
import React, {useContext} from 'react';
// local files
// 3rd party libraries
import {Container, Stack, TextField, Typography} from "@mui/material";
import {createAppState} from "./AppState";

export default function AdditionalComments() {

    const {
        additionalComments,
        isGlobalDisabled
    } = useContext(createAppState)

    return (
        <Container maxWidth="sm">
            <Stack
                direction="column"
                spacing={1}
            >
                <Typography
                    variant="caption"
                    color="white"
                    align="left"
                >
                    Additional Comments:
                </Typography>
                <TextField
                    id="outlined-multiline-static"
                    label="Additional Comments"
                    multiline
                    rows={6}
                    defaultValue={''}
                    placeholder="Add any additional information relevant to the draw, or leave empty for 'None.'"
                    onChange={(event) => additionalComments.value = event.target.value}
                    value={additionalComments.value}
                    fullWidth
                    sx={{marginTop: 1}}
                    disabled={isGlobalDisabled.value}
                />
            </Stack>
        </Container>
    )
}