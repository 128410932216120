import * as React from 'react';
import {useContext} from 'react';
import {Container, InputAdornment, Stack, TextField, Typography} from "@mui/material";
import {createAppState} from "./AppState";
import {effect} from "@preact/signals-react";

export default function Discrepancies() {

    const {
        draws,
        selectedDraw,
        requestedItems,
        varianceComments
    } = useContext(createAppState)

    const handleVariance = (event, index, item) => {
        let newState = [...varianceComments.value]
        newState[index] = {
            lineItem: item,
            comment: event.target.value
        }
        varianceComments.value = newState
    };

    effect(() => {
        if (selectedDraw.value && draws.value.length > 0) {
            // get line items from selected draw
            const lineItems = draws.value.find((draw) => draw.id === selectedDraw.value).lineItems
            // map requested items
            requestedItems.value = lineItems.filter((item) => {
                return !!(item.hardCosts && item.requestedAmount > 0);
            }).map((item, index) => {
                return (
                    <TextField
                        label={item.name}
                        key={item.id}
                        id={item.id}
                        variant="standard"
                        size="small"
                        multiline
                        onChange={(event) => handleVariance(event, index, item.name)}
                        value={varianceComments.value[index] === undefined ? "" : varianceComments.value[index].comment}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">•</InputAdornment>,
                        }}
                    />
                )
            })
        }
    })

    return (
        <Container maxWidth="sm">
            <Stack
                direction="column"
                spacing={1}
                marginBottom={1}
            >
                <Typography
                    variant="caption"
                    color="white"
                    align="left"
                >
                    Discrepancies (leave any line items empty to exclude):
                </Typography>
                {requestedItems.value}
            </Stack>
        </Container>
    )
}