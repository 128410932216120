// react core
import React, {useContext} from 'react';
import {Container, Stack, Typography} from "@mui/material";
import CurrencyTextField from "@lupus-ai/mui-currency-textfield";
import {createAppState} from "./AppState";

export default function FundingInformation() {


    const {
        totalBudget,
        requestedAmount,
        recommendedDisbursement,
        totalDeductions,
        isGlobalDisabled
    } = useContext(createAppState)

    // match requested value if recommended is greater
    const handleDisbursement = (event, value) => {
        if (value <= requestedAmount.value) {
            recommendedDisbursement.value = value
        } else {
            recommendedDisbursement.value = requestedAmount.value
        }
    }

    return (
        <Container maxWidth="sm">
            <Stack
                direction="column"
                spacing={1}
            >
                <Typography
                    variant="caption"
                    color="white"
                    align="left"
                >
                    Funding information:
                </Typography>
                <CurrencyTextField
                    label="Current Budget"
                    variant="outlined"
                    value={totalBudget.value}
                    currencySymbol="$"
                    minimumValue="0"
                    outputFormat="string"
                    decimalCharacter="."
                    digitGroupSeparator=","
                    disabled
                />
                <CurrencyTextField
                    label="Requested Amount"
                    variant="outlined"
                    value={requestedAmount.value}
                    currencySymbol="$"
                    minimumValue="0"
                    outputFormat="string"
                    decimalCharacter="."
                    digitGroupSeparator=","
                    disabled
                />
                <CurrencyTextField
                    label="Recommended Disbursement *"
                    variant="outlined"
                    value={recommendedDisbursement.value}
                    currencySymbol="$"
                    minimumValue="0"
                    outputFormat="string"
                    decimalCharacter="."
                    digitGroupSeparator=","
                    onChange={handleDisbursement}
                    disabled={isGlobalDisabled.value}
                />
                <CurrencyTextField
                    label="Total Deductions"
                    variant="outlined"
                    value={totalDeductions.value}
                    currencySymbol="$"
                    minimumValue="0"
                    outputFormat="string"
                    decimalCharacter="."
                    digitGroupSeparator=","
                    disabled
                />
            </Stack>
        </Container>
    )
}