
import { createTheme } from "@mui/material/styles";

const selectionTheme = createTheme({
    palette: {
        mode: "dark",
        background: {
            paper: '#480032'
        },
        text: {
            primary: '#ffffff'
        },
        primary: {
            main: '#ffffff'
        },
        secondary: {
            main: '#ffffff'
        }
    },
    typography: {
        body1: {
            fontSize: '1rem'
        }
    },
    components: {
        MuiFormLabel: {
            variants: [
                {
                    props: { variant: 'small-label' },
                    style: {
                        fontSize: `0.7rem`
                    },
                },
            ]
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    color: "whitesmoke"
                }
            }
        },
        MuiSnackbarContent: {
            styleOverrides: {
                root: {
                    color: "whitesmoke",
                    background: "#DF0054"
                }
            }
        }
    },
    spacing: 15
})

export default selectionTheme;