import * as React from 'react';
import {useContext} from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {createAppState} from "./AppState";

export default function SelectSubmitter() {

    const {
        selectedSubmitter,
        isGlobalDisabled
    } = useContext(createAppState)

    return (
        <FormControl fullWidth disabled={isGlobalDisabled.value}>
            <InputLabel id="requester-types" required>Requester Type</InputLabel>
            <Select
                id="requester-types"
                value={selectedSubmitter.value}
                label="Requester Type"
                onChange={(event) => selectedSubmitter.value = event.target.value}
            >
                <MenuItem value='Borrower'>Borrower</MenuItem>
                <MenuItem value='Sponsor'>Sponsor</MenuItem>
            </Select>
        </FormControl>
    );
}