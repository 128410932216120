// react core
import React, {useContext} from 'react';
import PropTypes from 'prop-types';
// 3rd party
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {Container} from "@mui/material";
// local files
import LogoutBar from "./LogoutBar";
import {useAuth0} from "@auth0/auth0-react";
import {LoadingPage} from "../pages/LoadingPage";
import CoverSheetLayout from "./CoverSheetLayout";
import {createAppState} from "./AppState";

// preact signal

function TabPanel(props) {

    const {children, value, index, ...other} = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function ToggleServices() {

    // auth0 react hook
    const {loginWithRedirect, isAuthenticated, isLoading, getAccessTokenSilently} = useAuth0()
    const {
        accessToken,
        projects
    } = useContext(createAppState)

    // check if the user is authenticated, if not, execute login method
    if (!isAuthenticated && !isLoading) {
        loginWithRedirect()
            .then(() => {
                // do nothing
            })
    }

    // set default tab index to 0
    const [value, setValue] = React.useState(0);

    // handle switching between tabs
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // conditionally render the loading page if the user is not logged in
    if (isAuthenticated) {
        getAccessTokenSilently()
            .then((response) => {
                accessToken.value = response
                const authHeader = {
                    withCredentials: true,
                    credentials: 'include',
                    headers: {'Authorization': `Bearer ${accessToken}`}
                }
                fetch('https://qdproxy.services/rabbet/projects', authHeader)
                    .then(response => {
                        if (response.ok) {
                            return response.json()
                        } else {
                            throw (response.error())
                        }
                    })
                    .then(data => {
                        projects.value = data.data
                    })
                    .catch((error) => {
                        //FIXME: configure error alert
                        console.log(error)
                    })
            })

        return (
            <Container>
                <Box
                    sx={{
                        paddingTop: 2,
                        borderBottom: 1,
                        borderColor: 'divider',
                        display: "flex",
                        justifyContent: "space-evenly"
                    }}
                >
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="draw tool tabs"
                    >
                        <Tab label="Draw Cover Sheet" {...a11yProps(0)}/>
                    </Tabs>
                </Box>
                <TabPanel
                    value={value}
                    index={0}
                >
                    <CoverSheetLayout/>
                </TabPanel>
                <LogoutBar/>
            </Container>
        )
    } else {
        return (<LoadingPage/>)
    }
}