import {Container, Stack, TextField, Typography} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import SelectSubmitter from "./SelectSubmitter";
import React, {useContext} from "react";
import {createAppState} from "./AppState";
import SelectProject from "./SelectProject";
import SelectDraw from "./SelectDraw";
import FormControl from "@mui/material/FormControl";


export default function DrawInformation() {

    const {
        drawDate,
        drawPackageDate,
        selectedDraw,
        isGlobalDisabled,
        UUID
    } = useContext(createAppState)

    return (
        <Container maxWidth="sm">
            <Stack
                direction="column"
                spacing={1}
            >
                <Typography
                    variant="caption"
                    color="white"
                    align="left"
                >
                    Draw information:
                </Typography>
                <SelectProject/>
                <SelectDraw/>
                <Stack
                    direction="row"
                    spacing={1}
                    id={'contains-selection-menus'}
                >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <FormControl fullWidth>
                            <DatePicker
                                label="Draw Received *"
                                value={drawDate.value}
                                onChange={(newValue) => drawDate.value = newValue}
                                id="draw-received"
                                key={`${selectedDraw.value}-draw-date`}
                                disabled={isGlobalDisabled.value}
                            />
                        </FormControl>
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <FormControl fullWidth>
                            <DatePicker
                                label="Package Received"
                                value={drawPackageDate.value}
                                onChange={(newValue) => drawPackageDate.value = newValue}
                                id="package-received"
                                key={`${selectedDraw.value}-package-date`}
                                disabled={isGlobalDisabled.value}
                            />
                        </FormControl>
                    </LocalizationProvider>
                </Stack>
                <SelectSubmitter/>
                <TextField
                    placeholder="UUID"
                    value={UUID.value}
                    disabled
                    key={UUID.value}
                />
            </Stack>
        </Container>
    )
}
