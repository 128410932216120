import * as React from 'react';
import {useContext} from 'react';
import Snackbar from '@mui/material/Snackbar';
import {createAppState} from "./AppState";
import {IconButton} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close'

export default function StatusAlert() {

    const {
        alert,
    } = useContext(createAppState)


    const handleClose = () => {
        alert.value = {isOpen: false, message: ""}
    }

    const action = (
        <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
        >
            <CloseIcon fontSize="small"/>
        </IconButton>
    )

    return (
        <Snackbar
            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            open={alert.value.isOpen}
            onClose={handleClose}
            autoHideDuration={6000}
            message={alert.value.message}
            action={action}
            key={alert.value.message}
        />
    )
}